<template>
    <!-- 个人数据 —— 业绩数据 -->
    <div class="performance-data" v-loading="loading">
        <div class="pannel-title">
            <div class="title-left">
                <span class="title-text">业绩数据</span>
                <!-- <span class="offer-count" @click="jumpOfferStatement($event, 'offer')">offer<i>{{offerCount | currency('',0)}}</i></span>
                <span v-if="isOpenPerformanceCalculation" class="offer-count relate-offer-count" @click="jumpOfferStatement($event, 'relateOffer')">相关offer<i>{{relateOfferCount | currency('',0)}}</i></span> -->
                <span class="offer-count" @click="jumpOfferStatement($event, 'offer')">offer<i>{{data.offers | currency('',0)}}</i></span>
                <span v-if="isOpenPerformanceCalculation" class="offer-count relate-offer-count" @click="jumpOfferStatement($event, 'relateOffer')">相关offer<i>{{data.relateOffers | currency('',0)}}</i></span>
            </div>
            <div class="title-right">
                <div class="date-wrap triangle-drop" :class="isFocusDate?'triangle-drop-up':'triangle-drop-down'">
                    <el-date-picker
                        v-model="dateRange"
                        type="daterange"
                        start-placeholder="开始日期"
                        range-separator="-"
                        end-placeholder="结束日期"
                        size="small"
                        :editable="false"
                        align="right"
                        format="yyyyMMdd"
                        @change="handleDate"
                        @focus="handleFocusDate"
                        @blur="handleBlurDate"
                        :clearable="false"
                        :picker-options="expireTimeOption"
                        popper-class="performance-data-range"
                    ></el-date-picker>
                </div>
                <div v-if="page == 'teamData'" class="employee-select-wrap triangle-drop" :class="teamMemberPopoverVisible?'triangle-drop-up':'triangle-drop-down'">
                    <el-popover
                        popper-class="team-member-popover data-team-member-popover"
                        placement="bottom-end"
                        width="700"
                        trigger="click"
                        v-model="teamMemberPopoverVisible"
                    >
                        <team-member-select
                            ref="teamSelect"
                            :isAllMembers="true"
                            :isShowDimission="false"
                            :date="dateRange"
                            :isHistoryData="true"
                            :visible="teamMemberPopoverVisible"
                            :selected="param.userIds"
                            @item-select-cb="employeeSelect"
                        ></team-member-select>
                        <div slot="reference" class="employee-select-text">{{selectedMembersText}}</div>
                    </el-popover>
                </div>
                <span v-if="page == 'teamData' && subscribedCountFetched"  @click="handleShowSubscribe" class="data-btn subscribe-btn">
                    <font-icon href="#icon-date_ic_book"></font-icon>
                    报表订阅
                    {{subscribedCount}}
                </span>
            </div>
        </div>

        <ul class="performance-list">
            <li class="performance-item">
                <font-icon class="item-icon" href="#icon-date_ic_candidate"></font-icon>
                <div class="item-content item-content-multi">
                    <p class="main-count"><span @click="goToDetail($event, {key: 'candidateDetails', achievementDetailType: '1'})">人才创建<i>{{data.candidates | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span @click="goToDetail($event, {key: 'candidateDetails', achievementDetailType: '2'})">完整简历<i>{{data.completeCandidates | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span @click="goToDetail($event, {key: 'candidateDetails', achievementDetailType: '3'})">Profile数<i>{{data.profileCandidates | currency('',0)}}</i></span></p>
                </div>
            </li>
            <li class="performance-item">
                <font-icon class="item-icon" href="#icon-date_ic_xunfang"></font-icon>
                <div class="item-content">
                    <p class="main-count"><span @click="goToDetail($event, {key: 'candidateInquiryLogDetails', achievementDetailType: '1'})">有效寻访<i>{{data.validInquiryLogs | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span @click="goToDetail($event, {key: 'candidateInquiryLogDetails', achievementDetailType: '2'})">添加寻访<i>{{data.inquiryLogs | currency('',0)}}</i></span></p>
                </div>
            </li>
            <li class="performance-item">
                <font-icon class="item-icon" href="#icon-date_ic_phone"></font-icon>
                <div class="item-content">
                    <p class="main-count"><span @click="goToDetail($event, {key: 'callRecordDetails', achievementDetailType: '1'})">拨打电话<i>{{data.calls | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span  @click="goToDetail($event, {key: 'callRecordDetails', achievementDetailType: '2'})">有效通话<i>{{data.validCalls | currency('',0)}}</i></span></p>
                </div>
            </li>
            <li class="performance-item">
                <font-icon class="item-icon" href="#icon-date_ic_zhiwei"></font-icon>
                <div class="item-content">
                    <p class="main-count"><span @click="goToDetail($event, {key: 'jobDetails', achievementDetailType: '1'})">创建职位<i>{{data.jobs | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span @click="goToDetail($event, {key: 'jobDetails', achievementDetailType: '2'})">职位相关信息更新<i>{{data.keyUpdateJobs | currency('',0)}}</i></span></p>
                </div>
            </li>
            <li class="performance-item">
                <font-icon class="item-icon" href="#icon-date_ic_order"></font-icon>
                <div class="item-content item-content-multi">
                    <p class="main-count"><span @click="goToDetail($event, {key: 'orderDetails', achievementDetailType: '1'})">接单次数<i>{{data.orders | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span @click="goToDetail($event, {key: 'orderDetails', achievementDetailType: '2'})">内部职位<i>{{data.internalOrders | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span @click="goToDetail($event, {key: 'orderDetails', achievementDetailType: '3'})">外部职位<i>{{data.marketOrders | currency('',0)}}</i></span></p>
                </div>
            </li>
            <li class="performance-item">
                <font-icon class="item-icon" href="#icon-date_ic_recommend"></font-icon>
                <div class="item-content item-content-multi">
                    <p class="main-count"><span @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '1'})">推荐人才<i>{{data.recommendCandidates | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '2'})">接受推荐<i>{{data.acceptedRecommendCandidates | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '3'})">推荐淘汰<i>{{data.rejectedRecommendCandidates | currency('',0)}}</i></span></p>
                </div>
            </li>
            <li class="performance-item">
                <font-icon class="item-icon" href="#icon-date_ic_cishu"></font-icon>
                <div class="item-content item-content-multi">
                    <p class="main-count"><span @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '4'})">人才推荐<i>{{data.candidateRecommends | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span  @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '5'})">接受推荐<i>{{data.acceptedCandidateRecommends | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span  @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '6'})">推荐淘汰<i>{{data.rejectedCandidateRecommends | currency('',0)}}</i></span></p>
                </div>
            </li>
            <li class="performance-item">
                <font-icon class="item-icon" href="#icon-date_ic_success"></font-icon>
                <div class="item-content item-content-multi">
                    <p class="main-count"><span @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '8'})">面试通过<i>{{data.interviewPassedCandidates | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '7'})">面试人才<i>{{data.interviewCandidates | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '9'})">面试失败<i>{{data.interviewFailedCandidates | currency('',0)}}</i></span></p>
                </div>
            </li>

            <template v-if="page == 'personalData'">
                <!-- <li class="performance-item" :class="userInfo.isCFUser ? '' : 'performance-item-single'"> -->
                <li class="performance-item">
                    <font-icon class="item-icon" href="#icon-date_ic_candidate"></font-icon>
                    <div class="item-content">
                        <p class="main-count"><span @click="goToDetail($event, {key: 'trackingListDetails'})">TrackingList<i>{{data.tackingLists}}</i></span></p>
                        <!-- <p class="main-count"><span @click="goToDetail($event, {key: 'deliveryTargetReport', deliveryTargetType: '3'})">交付目标-客户<i></i></span></p> -->
                    </div>
                </li>
                <!-- 综合协调人 -->
                <!-- <li class="performance-item performance-item-single" v-if="isGeneralCoordinator"> -->
                <li class="performance-item" v-if="isGeneralCoordinator">
                    <font-icon class="item-icon" href="#icon-zonghexietiaoren"></font-icon>
                    <div class="item-content item-content-multi">
                        <!-- <p class="sub-count"><span @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '12'})">综合协调人客户推荐数<i>{{data.generalCoordinatorCandidateRecommends | currency('',0)}}</i></span></p>
                        <p class="sub-count"><span @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '13'})">综合协调人客户面试数<i>{{data.generalCoordinatorInterviewCandidates | currency('',0)}}</i></span></p> -->
                        <p class="sub-count"><span @click="goToDetail($event, {key: 'generalCoordinatorRecommendationDetails', achievementDetailType: '12'})">综合协调人客户推荐数<i>{{data.generalCoordinatorCandidateRecommends | currency('',0)}}</i></span></p>
                        <p class="sub-count"><span @click="goToDetail($event, {key: 'generalCoordinatorRecommendationDetails', achievementDetailType: '13'})">综合协调人客户面试数<i>{{data.generalCoordinatorInterviewCandidates | currency('',0)}}</i></span></p>
                        <p class="sub-count"><span @click="jumpOfferStatement($event, 'clientOffer')">综合协调人客户offer数<i>{{data.generalCoordinatorOffers | currency('',0)}}</i></span></p>
                    </div>
                </li>
                <li class="performance-item">
                    <font-icon class="item-icon" href="#icon-yewuxian"></font-icon>
                    <div class="item-content item-content-multi">
                        <p class="sub-count"><span @click="goToDetail($event, {key: 'deliveryTargetReport', deliveryTargetType: '3'})">交付目标-客户<i></i></span></p>
                        <p class="sub-count" v-if="isBusinessCoordinator"><span @click="goToDetail($event, {key: 'businessLineData'})">业务线数据<i></i></span></p>
                    </div>
                </li>
                <li class="performance-item performance-item-single">
                    <font-icon class="item-icon" href="#icon-a-bianzu3"></font-icon>
                    <div class="item-content item-content-multi">
                        <p class="sub-count sub-count"><span @click="goToDetail($event, {key: 'evaluateCountReport'})">评价数 <i>{{data.recommendationEvaluateCount | currency('',0)}}</i></span></p>
                    </div>
                </li>
                <!-- <li class="performance-item"></li> -->
                <!-- <li class="performance-item"></li> -->
                <!-- <li class="performance-item"></li> -->
            </template>
            <template v-if="page == 'teamData'">
                <li class="performance-item" :class="userInfo.isCFUser ? '' : 'performance-item-single'">
                    <font-icon class="item-icon" href="#icon-date_ic_candidate"></font-icon>
                    <div class="item-content">
                        <p class="main-count"><span @click="goToDetail($event, {key: 'trackingListDetails'})">Tracking List<i>{{data.tackingLists | currency('',0)}}</i></span></p>
                        <p v-if="isAdminOrFirstLevelTeamLeader" class="sub-count"><span @click="goToDetail($event, {key: 'leaderFeedbackData'})">Tracking List周报反馈数据</span></p>
                    </div>
                </li>
                <li v-if="userInfo.isCFUser" class="performance-item" :class="userInfo.isCFUser && userInfo.isAdminOrTeamLeader ? '' : 'performance-item-single'">
                    <font-icon class="item-icon" href="#icon-zhitongche-2"></font-icon>
                    <div class="item-content item-content-multi">
                        <p class="main-count"><span @click="goToDetail($event, {key: 'jdtReportData', jdtReportDataType: '0'})">职通车推荐注册人数<i>{{data.newJDTRegistrant | currency('',0)}}</i></span></p>
                        <p class="sub-count"><span @click="goToDetail($event, {key: 'jdtReportData', jdtReportDataType: '1'})">分享职位数<i>{{data.jobShareInJDT | currency('',0)}}</i></span></p>
                        <p class="sub-count"><span @click="goToDetail($event, {key: 'jdtReportData', jdtReportDataType: '2'})">发消息给我的人数<i>{{data.receiveIMMessageByJDTUsers | currency('',0)}}</i></span></p>
                        <p class="sub-count"><span @click="goToDetail($event, {key: 'jdtReportData', jdtReportDataType: '3'})">我发消息的人数<i>{{data.sendIMMessageToJDTUsers | currency('',0)}}</i></span></p>
                    </div>
                </li>
                <li v-if="userInfo.isAdministrator || userInfo.isAdminOrTeamLeader" class="performance-item">
                    <font-icon class="item-icon" href="#icon-jiaofumubiao"></font-icon>
                    <div class="item-content">
                        <p class="sub-count sub-count-padding"><span @click="goToDetail($event, {key: 'deliveryTargetReport', deliveryTargetType: '0'})">交付目标-客户</span></p>
                        <p class="sub-count"><span @click="goToDetail($event, {key: 'deliveryTargetReport', deliveryTargetType: '1'})">交付目标-成员</span></p>
                    </div>
                </li>
                <li v-else class="performance-item"></li>
                <!-- 团队积分 -->
                <li v-if="(userInfo.isAdministrator || userInfo.isAdminOrTeamLeader) && userInfo.isCFUser" class="performance-item performance-item-single">
                    <font-icon class="item-icon" href="#icon-jifen"></font-icon>
                    <div class="item-content item-content-multi">
                        <p class="sub-count"><span @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '9'})">团队积分<i>{{data.interviewFailedCandidates | currency('',0)}}</i></span></p>
                    </div>
                </li>
                <li v-if="!userInfo.isCFUser" class="performance-item"></li>
                <!-- <li class="performance-item"></li> -->
            </template>
        </ul>
    </div>
</template>
<script>
import TeamMemberSelect from '#/component/common/team-member-select.vue';
import { statistics as statisticsUrl } from '#/js/config/api.json';
import { offerReport as offerReportUrl } from '#/js/config/javaApi.json';
import moment from 'moment';
// import SubscribeModal from '@/component/statistics/subscribe-modal/index';
import talentPoolService from '#/js/service/talentPoolService.js';

import 'swiper/dist/css/swiper.css';
import {swiper, swiperSlide} from 'vue-awesome-swiper';

const DEFAULT_PARAM = {
        startDate: '',
        endDate: '',
        filterMarketJob: true,
        filterInternalJob: true,
        dimension: 1
    }
export default {
    name: 'personalPerformanceData',
    components:{
        swiper,
        swiperSlide,
        TeamMemberSelect,
        // SubscribeModal
    },
    props: {
        page: String  //personalData: 个人数据， teamData: 团队数据
    },
    data() {
        return {
            isGeneralCoordinator: false, // 判断该用户有没有综合协调人角色
            isBusinessCoordinator: false, // 判断该用户有没有业务线协调人角色
            teamMemberPopoverVisible: false,
            selectedMembersText: '全部成员',
            data: {
                "generalCoordinatorCandidateRecommends": '',
                "generalCoordinatorInterviewCandidates": '',
                "generalCoordinatorOffers": '',
                "offers": '',
                "relatedOffers": '',
                "candidates": '',
                "completeCandidates": '',
                "profileCandidates": '',
                "inquiryLogs": '',
                "validInquiryLogs": '',
                "calls": '',
                "validCalls": '',
                "jobs": '',
                "keyUpdateJobs": '',
                "orders": '',
                "marketOrders": '',
                "internalOrders": '',
                "recommendCandidates": '',
                "acceptedRecommendCandidates": '',
                "rejectedRecommendCandidates": '',
                "candidateRecommends": '',
                "acceptedCandidateRecommends": '',
                "rejectedCandidateRecommends": '',
                "interviewCandidates": '',
                "interviewPassedCandidates": '',
                "interviewFailedCandidates": '',
                "tackingLists": "",
                "newJDTRegistrant": '',
                "jobShareInJDT": "",
                "receiveIMMessageByJDTUsers": "",
                "sendIMMessageToJDTUsers": "",
                "recommendationEvaluateCount": ""
            },
            swiperOption: {
                loop: false,
                slidesPerView: 1,
                pagination: {
                    el: '.swiper-pagination',
                    clickable :true,
                },
                updateTranslate: true,
            },
            // offerCount: 0,
            // relateOfferCount: 0,
            param: Object.assign({}, DEFAULT_PARAM,{
                    userIds: []
                }),
            dateRange: [],
            subscribedCountFetched: false,
            subscribedCount: 0,
            loading: false,
            expireTimeOption: {
                disabledDate(date) {
                    return date.getTime() > Date.now();
                },
                shortcuts: [{
                    text: '昨天',
                    onClick(picker) {
                        const end = moment().subtract(1, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(1, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '今天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '最近7天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(6, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近30天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(29, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '本周',
                    onClick(picker) {
                        const start = moment().weekday(0).format('YYYY-MM-DD');
                        const end = moment().weekday(6).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '本月',
                    onClick(picker) {
                        const start = moment().add('month', 0).format('YYYY-MM') + '-01';
                        const end = moment(start).add('month', 1).add('days', -1).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '上月',
                    onClick(picker) {
                        const start = moment().subtract(1, 'month').format('YYYY-MM') + '-01';
                        const end = moment(start).subtract(-1, 'month').add('days', -1).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '今年',
                    onClick(picker) {
                        const start = moment().year() + '-01-01';
                        const end = moment().format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            isFocusDate: false,//用于控制日期的三角
            isAdminOrFirstLevelTeamLeader: false
        }
    },
    mounted() {
        // this.subscribeModal = this.$refs.subscribeModal;
        let yestoday = moment(new Date()).add(-1, 'days');
        this.dateRange = [yestoday,yestoday];
        this.param = Object.assign({},this.param,{
           startDate: this.dateRange[0].format('YYYY-MM-DD'),
           endDate: this.dateRange[1].format('YYYY-MM-DD')
        });
        if(this.page == 'teamData') {
            this.loading = true;
            this.$refs.teamSelect.getHistoryTeamData(true);
            this.getSubscribeData();
            this.getTeamInfo();
        }else if(this.page == 'personalData') {
            this.param.userIds = [this.userInfo.id];
            this.refresh();
        }
        // console.log(this.userInfo.roleCodeList)
        this.userInfo.privilegeCodeList.forEach(el => {
            if(el == 'GeneralCoordinator') {
                this.isGeneralCoordinator = true;
            }
            if(el == 'BusinessCoordinator') {
                this.isBusinessCoordinator = true;
            }
        })
        // console.log(this.isGeneralCoordinator)
        // console.log(this.isBusinessCoordinator)
    },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        isOpenPerformanceCalculation() {
            return this.$store.state.user.userInfo.isOpenPerformanceCalculation;
        },
    },
    methods: {
        getTeamInfo() {
            talentPoolService
                .getTeamInfo()
                .then((res) => {
                    if(res.isAdmin || res.isFirstLevelTeamLeader) {
                        this.isAdminOrFirstLevelTeamLeader = true;
                    }
                })
        },
        // 员工选取
        employeeSelect(userIds, text) {
            this.param.userIds = userIds;
            this.selectedMembersText = text;
            this.refresh();
            this.teamMemberPopoverVisible = false;
        },
        refresh(param){
            this.loading = true;
            let url = statisticsUrl.url_firm_statistics;
            this.param = Object.assign({},this.param,param);
            _request({
                method: 'POST',
                url: url,
                data: this.param
            }).then(res => {
                this.data = Object.assign({},this.data,res);
                this.loading = false;
            }).catch(err => {
                console.log(err)
                this.loading = false;
            }).finally(() => {
                // Todo:这里异步请求不优雅，为了解决bug，后期优化
                // let getCountParam = {
                //     recommendStartTime: this.param.startDate,
                //     recommendEndTime: this.param.endDate,
                //     recommendOwnerIds: this.param.userIds
                // }

                // _request({
                //     method: 'POST',
                //     baseURL: 'LbdJavaApi',
                //     javaProject: 'performance',
                //     url: offerReportUrl.get_offer_count,
                //     data: getCountParam
                // }).then(res =>{
                //     this.offerCount = res.offerCount;
                //     this.relateOfferCount = res.relateOfferCount;
                // }).finally(() => {
                    // 从java获取部分统计数据，其它数据从以前的接口获取
                    const getSummaryParam = {
                        startDate: this.param.startDate.replace(/\//g, '-'),
                        endDate: this.param.endDate.replace(/\//g, '-'),
                        userIds: this.param.userIds,
                    };

                    _request({
                        method: 'POST',
                        baseURL: 'LbdJavaApi',
                        javaProject: 'performance',
                        url: offerReportUrl.get_statistics_summary,
                        data: getSummaryParam
                    }).then(res =>{
                        Object.assign(this.data, res);
                    });
                // })
            })

        },
        handleDate(value){
            this.param.startDate = moment(value[0]).format('YYYY-MM-DD');
            this.param.endDate = moment(value[1]).format('YYYY-MM-DD');
            if(this.page == 'teamData') {
                this.loading = true;
                this.$refs.teamSelect.getHistoryTeamData(true);
            }else if(this.page == 'personalData') {
                this.refresh();
            }
        },
        goToDetail(ev, json){
            ev.stopPropagation();
            this.jumpDetail(json);
        },
        jumpDetail(data) {
            console.log(data);
            let key = data.key;

            if(key == 'trackingListDetails') {
                _tracker.track('openTrackingListReport');
            }

            let json = {};
            let { userIds, startDate, endDate } = this.param;
            let isFromPerformance = true;
            json = { userIds, startDate, endDate, isFromPerformance };
            if(data.userIds) {
                json.userIds = data.userIds;
            }
            console.log(json);
            Object.assign(json,data);
            sessionStorage.removeItem('perpormancesDetailData');
            localStorage.setItem('perpormancesDetailData', JSON.stringify(json));
            if(key == 'jdtReportData') {
                _tracker.track('openJdtReport', JSON.stringify({
                    type: data.jdtReportDataType
                }));
                window.open('/Headhunting/MyCompany.html#/jdtReport', '_blank');
                // window.open('/#/jdtReport', '_blank');
            } else if(key == 'deliveryTargetReport') {
                _tracker.track('openDeliveryTargetReport', JSON.stringify({
                    type: data.deliveryTargetType
                }));
                window.open('/Headhunting/MyCompany.html#/deliveryTarget', '_blank');
                // window.open('/#/deliveryTarget', '_blank');
            } else if(key == 'leaderFeedbackData') {
                _tracker.track('openLeaderFeedbackData');
                window.open('/Headhunting/MyCompany.html#/leaderFeedbackData', '_blank');
                // window.open('/#/leaderFeedbackData', '_blank');
            } else if(key == 'businessLineData') {
                _tracker.track('openBusinessLineData');
                window.open('/Headhunting/MyCompany.html#/businessLineData', '_blank');
                // window.open('/#/businessLineData', '_blank');
            } else if(key == 'evaluateCountReport') {
                sessionStorage.removeItem('evaluateCountReport');

                _tracker.track('openPersonalEvaluateCountReport');
                window.open('/Headhunting/MyCompany.html#/personalEvaluateCountReport', '_blank');
                // window.open('/#/personalEvaluateCountReport', '_blank');
            } else {
                window.open('/Headhunting/MyCompany.html#/performancesDetail', '_blank');
                // window.open('/#/performancesDetail', '_blank');
            }
            return false;
        },
        jumpOfferStatement(ev, type) {
            ev.stopPropagation();
            let json = {};
            let { userIds, startDate, endDate } = this.param;
            json = { userIds, startDate, endDate, isTeam: 0, };
            sessionStorage.removeItem('offerReportData');
            localStorage.setItem('offerReportData', JSON.stringify(json));
            if(type == 'offer') {
                _tracker.track('clickOfferReportForm');
                window.open('/Headhunting/MyCompany.html#/offerReport?type=offer');
                // window.open('/#/offerReport?type=offer');
            }else if(type == 'relateOffer') {
                _tracker.track('clickInvolvedOfferReportForm');
                window.open('/Headhunting/MyCompany.html#/offerReport?type=relateOffer');
                // window.open('/#/offerReport?type=relateOffer');
            }else if(type == 'clientOffer') {
                _tracker.track('clickOfferReportForm');
                window.open('/Headhunting/MyCompany.html#/offerReport?type=clientOffer');
                // window.open('/#/offerReport?type=clientOffer');
            }
            return false;
        },
        handleShowSubscribe() {
            // if(this.subscribedCount > 0) {
            //     this.subscribeModal.addSubscribeShow = false;
            // }else {
            //     this.subscribeModal.addSubscribeShow = true;
            // }
            // this.subscribeModal.showModal();
        },
        getSubscribeData() {
            let url = statisticsUrl.subscribe_list;
            _request({
                method: 'POST',
                url: url,
            }).then(res => {
                this.subscribedCountFetched = true;
                this.subscribedCount = res.length;
            }).catch(err => {
                console.log(err)
            })

        },
        handleFocusDate(){
            this.isFocusDate = true;
        },
        handleBlurDate(){
            this.isFocusDate = false;
        }
    }
}
</script>
<style lang="scss" scoped>
.performance-data{
    position: relative;
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;

    /deep/ .swiper-container {
        width: 100%;
        height: 100%;

        .swiper-pagination-bullets {
            bottom: 0;

            .swiper-pagination-bullet {
                width:12px;
                height: 12px;
            }
            .swiper-pagination-bullet-active {
                background: $primary;
            }
        }
    }

    .performance-list{
        height: 100%;
        padding: 20px 0;
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-around;
        .performance-item{
            width: 25%;
            text-align: center;
            align-self: center;
            &:nth-child(4n){
                .item-content{
                    border: none;
                }
            }

            &.performance-item-single .item-content {
                border: none;
            }

            .item-icon{
                font-size: 30px;
            }
            .item-content{
                border-right: 1px solid #eee;
                height: 66px;
                margin-top: 10px;
                p{
                    margin-bottom: 0;
                    span{
                        cursor: pointer;
                        &:hover{
                            color: #38BC9D;
                        }
                    }
                }
                i{
                    margin-left: 10px;
                }
                .main-count{
                    // font-size: 16px;
                    font-size: 14px;
                    color: #444;
                    margin-bottom: 10px;
                    i{
                        // font-size: 16px;
                        font-size: 14px;
                        color: #38BC9D;
                        font-weight: bold;
                    }
                }
                .sub-count{
                    font-size: 14px;
                    color: #666;
                    margin-top: 6px;
                    i{
                        font-weight: bold;
                        color: #38BC9D;
                    }
                }
                .sub-count-padding {
                    padding-top: 6px;
                }

                &.item-content-multi {
                    .main-count{
                        margin-bottom: 0;
                        white-space: nowrap;
                    }
                    .sub-count {
                        margin-top: 0;
                    } 
                }
            }
        }
    }
    .offer-count{
        display: inline-block;
        background: rgba(56,188,157,.1);
        border-radius: 10px;
        font-size: 16px;
        color: #444;
        height: 24px;
        line-height: 24px;
        padding: 0 10px;
        margin: 0 13px;
        cursor: pointer;
        &:hover{
            color: #38BC9D;
        }

        &.relate-offer-count {
            margin-left: 0;
        }

        >i{
            font-size: 16px;
            color: #38BC9D;
            margin-left: 7px;
        }
    }
    .employee-select-wrap{
        position: relative;
        margin-left: 10px;

        .employee-select-text {
            display: inline-block;
            width: 110px;
            height: 32px;
            line-height: 32px;
            border: 1px solid #d3d3d3;
            border-radius: 16px;
            padding: 0 36px 0 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &:hover{
            .employee-select-text{
                background: #38BC9D;
                border-color: #38BC9D;
                color: #fff;
            }

        }
        &.triangle-drop-up{
            &::after{
                border-bottom: 12px solid #fff;
            }
            .employee-select-text{
                background: #38BC9D;
                border-color: #38BC9D;
                color: #fff;
            }
        }
    }
    .data-btn{
        display: inline-block;
        border: 1px solid #DDD;
        border-radius: 16px;
        height: 32px;
        line-height: 32px;
        padding-left: 16px;
        color: #666;
        background-color: #fff;
        cursor: pointer;
        &.subscribe-btn{
            position: absolute;
            right: 20px;
            bottom: 0;
            border: none;
            white-space: nowrap;
            &:hover{
                // background: #38BC9D;
                color: $primary;
            }
        }
    }
    /deep/ .date-wrap{
        position: relative;
        cursor: pointer;
        &:hover{
            .el-date-editor--daterange.el-input__inner{
                border-color:#38BC9D;
                background: #38BC9D;
                .el-range-input{
                    background: #38BC9D;
                    color: #fff;
                }
                .el-range-separator{
                    color: #fff;
                }
            }
        }
        .el-range-editor.is-active{
            background: #38BC9D;
            .el-range-input{
                background: #38BC9D;
                color: #fff;
            }
            .el-range-separator{
                color: #fff;
            }
        }
        &.triangle-drop-up{
            &::after{
                border-bottom: 12px solid #fff;
            }
        }
        .el-date-editor--daterange.el-input__inner{
            width: 190px;
            border-radius: 16px;
        }
        .el-date-editor .el-range__icon{
            display: none;
        }

        .el-date-editor .el-range__close-icon{
            display: none;
        }
    }
    .triangle-drop{
        &:after{
            position: absolute;
            display: block;
            content: '';
            top: 50%;
            transform: translateY(-50%);
            right: 16px;
        }
        &-down{
            &:after{
                border-top: 12px solid #ddd;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
            }
            &:hover{
                &:after{
                    border-top: 12px solid #fff;
                }
            }

        }
        &-up{
            &:after{
                border-bottom: 12px solid #ddd;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
            }
            &:hover{
                &:after{
                    border-bottom: 12px solid #fff;
                }
            }
        }
    }


}
</style>
<style lang="scss">
.team-member-popover.data-team-member-popover {
    transform: translateX(0);
}
.performance-data-range.el-picker-panel{
    .el-date-range-picker__content.is-left{
        border-right: none;
    }
    .el-date-table{
        .el-date-table__row{
            .normal.disabled{
                div{
                    color: #666;
                }
            }
        }
    }
}
</style>
