<template>
    <div class="talent-reuse" v-loading="loading">
         <div class="tab-title">
             <span class="title-text" :class="{'active': activeName == 'recommend-progress'}" @click="handleClick('recommend-progress')">推荐进展</span>
             <span class="title-text" :class="{'active': activeName == 'talent-reuse'}" @click="handleClick('talent-reuse')">人才复用</span>
         </div>
         <div class="recommend-progress-data-content" v-show="activeName == 'recommend-progress'">
            <div class="option">
                <!-- 时间段选择 -->
                <div class="date-wrap">
                    <el-date-picker
                        v-model="dateRange"
                        type="daterange"
                        start-placeholder="开始日期"
                        range-separator="-"
                        end-placeholder="结束日期"
                        size="small"
                        :editable="false"
                        align="right"
                        format="yyyyMMdd"
                        @change="handleDate"
                        :clearable="false"
                        :picker-options="expireTimeOption"
                    ></el-date-picker>
                </div>
                <el-button 
                    type="primary" 
                    class="performances-detail-el-export"
                    @click="handleExport"
                    :disabled="isLoadingExport"
                    :loading="isLoadingExport">导出明细</el-button>
            </div>
            <div class="data-wrap">
                <div class="total">产生推荐：<span style="font-weight: bold;">{{ recommendProcessDetail.total }}</span></div>
                <div class="data-detail">
                    <ul class="left">
                        <li>
                            <span>待反馈：</span>
                            <span class="count">{{ recommendProcessDetail.hrfeedBack?.count || 0 }}</span>
                        </li>
                        <li>
                            <span>淘汰（未进入面试）：</span>
                            <span class="count">{{ recommendProcessDetail.obsoletedFrontInterview?.count || 0 }}</span>
                        </li>
                        <li>
                            <span>面试中：</span>
                            <span class="count">{{ recommendProcessDetail.interview?.count || 0 }}</span>
                        </li>
                        <li>
                            <span>淘汰（面挂）：</span>
                            <span class="count">{{ recommendProcessDetail.obsoletedBackInterview?.count || 0 }}</span>
                        </li>
                        <li>
                            <span>offering：</span>
                            <span class="count">{{ recommendProcessDetail.offering?.count || 0 }}</span>
                        </li>
                    </ul>
                    <ul class="right">
                        <li>
                            <span>淘汰（未进入offer）：</span>
                            <span class="count">{{ recommendProcessDetail.obsoletedOffering?.count || 0 }}</span>
                        </li>
                        <li>
                            <span>已offer：</span>
                            <span class="count">{{ recommendProcessDetail.offer?.count || 0 }}</span>
                        </li>
                        <li>
                            <span>淘汰（飞单）：</span>
                            <span class="count">{{ recommendProcessDetail.obsoletedOffer?.count || 0 }}</span>
                        </li>
                        <li>
                            <span>入职</span>
                            <span class="count">{{ recommendProcessDetail.onboard?.count || 0 }}</span>
                        </li>
                    </ul>
                </div>
            </div>
         </div>
         <div class="data-content" v-show="activeName == 'talent-reuse'">
            <div class="data-list">
                <h3 class="talent-reuse-h3">我的人才</h3>
                <ul>
                    <li @click="goToTalentReuse({key: 'ViewCandidates', candidateViewType: 0, isMyCandidate: true})">
                        我的简历被浏览
                        <span
                            class="talent-reuse-number">{{ workbenchPanelData.myResumeByViewCount || 0 }}</span>
                    </li>
                    <li @click="goToTalentReuse({key: 'ViewCandidates', candidateViewType: 1, isMyCandidate: true})">
                        我的简历电话被查看
                        <span class="talent-reuse-number">{{ workbenchPanelData.myResumePhoneByViewCount || 0 }}</span>
                    </li>
                    <li @click="goToTalentReuse({key: 'ViewCandidates', candidateViewType: 2, isMyCandidate: true})">
                        我的简历邮箱被查看
                        <span class="talent-reuse-number">{{ workbenchPanelData.myResumeEmailByViewCount || 0 }}</span>
                    </li>
                    <li @click="goToTalentReuse({key: 'RecommendCandidates', recommendType: 0, isMyCandidate: true})">
                        我的简历被推荐
                        <span class="talent-reuse-number">{{ workbenchPanelData.myResumeByRecommendCount || 0 }}</span>
                    </li>
                    <li @click="goToTalentReuse({key: 'RecommendCandidates', recommendType: 1, isMyCandidate: true})">
                        我的简历被推荐拿到offer
                        <span class="talent-reuse-number">{{ workbenchPanelData.myResumeByRecommendOfferCount || 0 }}</span>
                    </li>
                </ul>
            </div>
            <div class="data-list">
                <h3 class="talent-reuse-h3 blue-title-pre">他人人才</h3>
                <ul>
                    <li @click="goToTalentReuse({key: 'ViewCandidates', candidateViewType: 0, isMyCandidate: false})">
                        我浏览同事的简历
                        <span
                            class="talent-reuse-number">{{ workbenchPanelData.viewOtherResumeCount || 0 }}</span>
                    </li>
                    <li @click="goToTalentReuse({key: 'ViewCandidates', candidateViewType: 1, isMyCandidate: false})">
                        我查看同事的简历电话
                        <span class="talent-reuse-number">{{ workbenchPanelData.viewOtherResumePhoneCount || 0 }}</span>
                    </li>
                    <li @click="goToTalentReuse({key: 'ViewCandidates', candidateViewType: 2, isMyCandidate: false})">
                        我查看同事的简历邮箱
                        <span class="talent-reuse-number">{{ workbenchPanelData.viewOtherResumeEmailCount || 0 }}</span>
                    </li>
                    <li @click="goToTalentReuse({key: 'RecommendCandidates', recommendType: 0, isMyCandidate: false})">
                        我推荐同事的简历
                        <span class="talent-reuse-number">{{ workbenchPanelData.recommendOtherResumeCount || 0 }}</span>
                    </li>
                    <li @click="goToTalentReuse({key: 'RecommendCandidates', recommendType: 1, isMyCandidate: false})">
                        我推荐同事的简历拿到offer
                        <span class="talent-reuse-number">{{ workbenchPanelData.recommendOtherResumeOfferCount || 0 }}</span>
                    </li>
                </ul>
            </div>
         </div>
    </div>
</template>
<script>
import performanceSetService from '#/js/service/performanceSetService.js';
import myService from "#/js/service/myService.js";
import { downloadFile } from "#/js/util/fileUtil.js";
import moment from 'moment';

export default {
    name: 'talentReuse',
    components:{

    },
    data() {
        return {
            loading: false,
            isLoadingExport: false,
            activeName: 'recommend-progress',
            param: {
                startDate: '',
                endDate: '',
                memberIds: [],
            },
            dateRange: [],
            expireTimeOption: {
                disabledDate(date) {
                    return date.getTime() > Date.now();
                },
                shortcuts: [{
                    text: '昨天',
                    onClick(picker) {
                        const end = moment().subtract(1, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(1, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '今天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '最近7天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(6, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近30天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(29, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '本周',
                    onClick(picker) {
                        const start = moment().weekday(0).format('YYYY-MM-DD');
                        const end = moment().weekday(6).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '本月',
                    onClick(picker) {
                        const start = moment().add('month', 0).format('YYYY-MM') + '-01';
                        const end = moment(start).add('month', 1).add('days', -1).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '上月',
                    onClick(picker) {
                        const start = moment().subtract(1, 'month').format('YYYY-MM') + '-01';
                        const end = moment(start).subtract(-1, 'month').add('days', -1).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '今年',
                    onClick(picker) {
                        const start = moment().year() + '-01-01';
                        const end = moment().format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            recommendProcessDetail: {},
            workbenchPanelData: {},
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
    },
    mounted() {
        this.getStatistics();

        let yestoday = moment(new Date()).add(-1, 'days');
        let weekday = moment(new Date()).add(-7, 'days');
        this.dateRange = [weekday,yestoday];
        // this.param = Object.assign({},this.param,{
        //    startDate: this.dateRange[0].format('YYYY-MM-DD'),
        //    endDate: this.dateRange[1].format('YYYY-MM-DD')
        // });
        this.param.startDate = this.dateRange[0].format('YYYY-MM-DD');
        this.param.endDate = this.dateRange[1].format('YYYY-MM-DD');
        this.getRecommendProcessDetail();
    },
    methods: {
        handleClick(tab) {
            console.log(tab);
            this.activeName = tab;
        },
        handleDate(value){
            console.log(value);
            this.param.startDate = moment(value[0]).format('YYYY-MM-DD');
            this.param.endDate = moment(value[1]).format('YYYY-MM-DD');
            this.getRecommendProcessDetail();
        },
        handleExport() {
            this.isLoadingExport = true;
            performanceSetService
                .exportRecommendProcessDetail(this.param)
                .then(res => {
                    // console.log(res);
                    shortTips(`导出成功！`);
                    downloadFile(res);
                    this.isLoadingExport = false;
                })
                .catch(err => {
                    console.log(err);
                    this.isLoadingExport = false;
                });
        },
        
        /**
            * 获取工作面板数据统计
            */
        getStatistics() {
            this.loading = true;
            myService
                .getStatistics()
                .then(res => {
                    if (res) {
                        this.workbenchPanelData = res;
                    }
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.loading = false;
                });
        },
        // 获取推荐进展详情
        getRecommendProcessDetail() {
            this.loading = true;
            this.param.memberIds.push(this.userInfo.id);
            // console.log(this.param);
            performanceSetService
                .getRecommendProcessDetail(this.param)
                .then(res => {
                    // console.log(res);
                    this.recommendProcessDetail = res;
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.loading = false;
                });
        },
        //跳转到人才复用详情页
        goToTalentReuse(data) {
            localStorage.setItem("talentReuseDetailData", JSON.stringify(data));
            _tracker.track(data.key, JSON.stringify(data));
            window.open(
                "/Headhunting/MyCompany.html?frompage=daily#/workbench/talentReuse",
                "_blank"
            );
            // window.open(
            //     "/?frompage=daily#/workbench/talentReuse",
            //     "_blank"
            // );
            return false;
        },
    }
}
</script>
<style lang="scss" scoped>
.talent-reuse{
    padding: 20px 20px 0 20px;
    .tab-title {
        font-size: 20px;
        color: #333;
        font-weight: bold;
        .title-text {
            cursor: pointer;
            margin-right: 20px;
            &.active {
                color: $primary;
            }
        }
    }
    .recommend-progress-data-content {
        .option {
            margin-top: 20px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .date-wrap {
                .el-range-editor--small.el-input__inner {
                    width: 250px;
                }
            }
        }
        .data-wrap {
            .total {
                font-size: 16px;
                margin: 20px 0 10px;
            }
            .data-detail {
                display: flex;
                ul {
                    padding: 0 40px;
                    li {
                        height: 30px;
                        line-height: 30px;
                        display: flex;
                        justify-content: space-between;
                        .count {
                            color: #333;
                        }
                    }
                }
                .left {
                    padding-right: 40px;
                    flex: 1;
                    border-right: 1px solid #ccc;
                }
                .right {
                    padding-left: 40px;
                    flex: 1;
                }
            }
        }
    }

    .data-content{
        display: flex;
        .data-list{
            flex-grow: 1;
            padding: 26px;
            padding-bottom: 0;
            font-size: 14px;
            .talent-reuse-h3{
                position: relative;
                text-shadow: 0 0 0.7px #444;
                font-size: 16px;
                padding-left: 21px;
                margin-bottom: 5px;
                &:before{
                    display: block;
                    content: '';
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    top: 50%;
                    left: 8px;
                    background: $primary;
                    transform: translateY(-50%);
                    border-radius: 50%;
                }
                &.blue-title-pre{
                    &:before{
                        background: #4A90E2;
                    }
                }
            }
            ul{
                li{
                    height: 32px;
                    line-height: 32px;
                    padding: 0 10px;
                    color: #666;
                    cursor: pointer;
                    &:hover{
                        color: $primary;
                        background: #F8F8F8;
                    }
                }
            }
            .talent-reuse-number {
                display: inline-block;
                float: right;
                font-size: 12px;
                font-weight: bold;
            }
        }
    }
}
</style>
