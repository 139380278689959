var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "daily-summary",
    },
    [
      _c(
        "div",
        { staticClass: "daily-summary-header" },
        [
          _c(
            "div",
            { staticClass: "daily-summary-top" },
            [
              _c("span", { staticClass: "daily-summary-title" }, [
                _vm._v("\n                今日工作小结\n            "),
              ]),
              _c("span", { staticClass: "daily-summary-date" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.dailySummary.date) +
                    "\n            "
                ),
              ]),
              _c("el-date-picker", {
                staticClass: "daily-summary-past",
                class: _vm.dailySummaryDate ? "icon-hide" : "",
                attrs: {
                  type: "date",
                  placeholder: "往期日报",
                  clearable: false,
                  editable: false,
                  align: "right",
                  "popper-class": !_vm.dailySummaryDate
                    ? "daily-summary-past-popover today-choose"
                    : "",
                  "picker-options": _vm.datePickerOption,
                },
                on: {
                  change: function ($event) {
                    return _vm.getDailySummary(_vm.dailySummaryDate)
                  },
                },
                model: {
                  value: _vm.dailySummaryDate,
                  callback: function ($$v) {
                    _vm.dailySummaryDate = $$v
                  },
                  expression: "dailySummaryDate",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "daily-summary-offer" }, [
            _vm._v("\n            offer\n            "),
            _c(
              "span",
              {
                staticClass: "font-green",
                on: {
                  click: function ($event) {
                    return _vm.goToDetail({
                      key: "recommendationDetails",
                      achievementDetailType: "10",
                    })
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.dailySummary.offers) +
                    "\n            "
                ),
              ]
            ),
          ]),
          _c(
            "el-popover",
            {
              staticClass: "daily-summary-qcode",
              attrs: {
                "popper-class": "daily-summary-qcode-popover",
                trigger: "hover",
                width: "80",
                placement: "top",
                "visible-arrow": false,
              },
            },
            [
              _vm.qrcode
                ? _c("div", [_c("img", { attrs: { src: _vm.qrcode } })])
                : _vm._e(),
              _c(
                "el-button",
                {
                  staticClass: "daily-summary-qcode-button",
                  class: _vm.isShowQcodeCard ? "qcode-show" : "",
                  attrs: { slot: "reference" },
                  on: {
                    click: function ($event) {
                      _vm.isShowQcodeCard = !_vm.isShowQcodeCard
                    },
                  },
                  slot: "reference",
                },
                [
                  _c("font-icon", {
                    staticClass: "icon-default",
                    attrs: { href: "#icon-date_ic_scan" },
                  }),
                  _c("font-icon", {
                    staticClass: "icon-hover",
                    attrs: { href: "#icon-date_ic_scan_hover" },
                  }),
                  _c("span", [_vm._v(" 扫码分享")]),
                ],
                1
              ),
            ],
            1
          ),
          _c("ul", { staticClass: "daily-summary-menu" }, [
            _c(
              "li",
              {
                staticClass: "daily-summary-menu-item",
                class: _vm.dailySummaryType == "order" ? "isActive" : "",
                on: {
                  click: function ($event) {
                    _vm.dailySummaryType = "order"
                  },
                },
              },
              [_vm._v("\n                订单\n            ")]
            ),
            _c(
              "li",
              {
                staticClass: "daily-summary-menu-item",
                class: _vm.dailySummaryType == "candidate" ? "isActive" : "",
                on: {
                  click: function ($event) {
                    _vm.dailySummaryType = "candidate"
                  },
                },
              },
              [_vm._v("\n                人才\n            ")]
            ),
            _c(
              "li",
              {
                staticClass: "daily-summary-menu-item",
                class: _vm.dailySummaryType == "recommend" ? "isActive" : "",
                on: {
                  click: function ($event) {
                    _vm.dailySummaryType = "recommend"
                  },
                },
              },
              [_vm._v("\n                业务流程\n            ")]
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "daily-summary-list" }, [
        _c(
          "ul",
          _vm._l(
            _vm.dailySummaryList[_vm.dailySummaryType],
            function (item, index) {
              return _c(
                "li",
                { key: index },
                [
                  item.hasChildren
                    ? [
                        _c(
                          "p",
                          {
                            staticClass: "daily-summary-item",
                            class:
                              _vm.dailySummary[item.type] !== undefined
                                ? "daily-summary-item-hover"
                                : "",
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.text) +
                                "\n                        "
                            ),
                            _vm.dailySummary[item.type] !== undefined
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "data-num",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToDetail(item.param)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.dailySummary[item.type]))]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "ul",
                          { style: index == 3 ? "margin-bottom: 0;" : "" },
                          [
                            item.type !== "candidateCommunication"
                              ? _vm._l(item.list, function (subItem, subIndex) {
                                  return _c("li", { key: subIndex }, [
                                    _c(
                                      "p",
                                      { staticClass: "daily-summary-sub-item" },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(subItem.text) +
                                            "\n                                    "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "data-num",
                                            on: {
                                              click: function ($event) {
                                                return _vm.goToDetail(
                                                  subItem.param
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.dailySummary[subItem.type]
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                })
                              : [
                                  _c("li", [
                                    _c(
                                      "p",
                                      { staticClass: "daily-summary-sub-item" },
                                      [
                                        _vm._v(
                                          "\n                                    添加寻访/有效寻访\n                                    "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "data-num",
                                            on: {
                                              click: function ($event) {
                                                return _vm.goToDetail({
                                                  key: "candidateInquiryLogDetails",
                                                  achievementDetailType: "1",
                                                })
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.dailySummary
                                                  .validInquiryLogs
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticStyle: { float: "right" } },
                                          [_vm._v(" / ")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "data-num",
                                            on: {
                                              click: function ($event) {
                                                return _vm.goToDetail({
                                                  key: "candidateInquiryLogDetails",
                                                  achievementDetailType: "2",
                                                })
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.dailySummary.inquiryLogs
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c(
                                      "p",
                                      { staticClass: "daily-summary-sub-item" },
                                      [
                                        _vm._v(
                                          "\n                                    拨打电话/有效拨打电话数\n                                    "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "data-num",
                                            on: {
                                              click: function ($event) {
                                                return _vm.goToDetail({
                                                  key: "callRecordDetails",
                                                  achievementDetailType: "2",
                                                })
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.dailySummary.validCalls
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticStyle: { float: "right" } },
                                          [_vm._v(" / ")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "data-num",
                                            on: {
                                              click: function ($event) {
                                                return _vm.goToDetail({
                                                  key: "callRecordDetails",
                                                  achievementDetailType: "1",
                                                })
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.dailySummary.calls)
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                          ],
                          2
                        ),
                      ]
                    : [
                        _c(
                          "p",
                          {
                            staticClass:
                              "daily-summary-item daily-summary-item-hover",
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.text) +
                                "\n                        "
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "data-num",
                                on: {
                                  click: function ($event) {
                                    return _vm.goToDetail(item.param)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.dailySummary[item.type]))]
                            ),
                          ]
                        ),
                      ],
                ],
                2
              )
            }
          ),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }