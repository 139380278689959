var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "performance-data",
    },
    [
      _c("div", { staticClass: "pannel-title" }, [
        _c("div", { staticClass: "title-left" }, [
          _c("span", { staticClass: "title-text" }, [_vm._v("业绩数据")]),
          _c(
            "span",
            {
              staticClass: "offer-count",
              on: {
                click: function ($event) {
                  return _vm.jumpOfferStatement($event, "offer")
                },
              },
            },
            [
              _vm._v("offer"),
              _c("i", [
                _vm._v(_vm._s(_vm._f("currency")(_vm.data.offers, "", 0))),
              ]),
            ]
          ),
          _vm.isOpenPerformanceCalculation
            ? _c(
                "span",
                {
                  staticClass: "offer-count relate-offer-count",
                  on: {
                    click: function ($event) {
                      return _vm.jumpOfferStatement($event, "relateOffer")
                    },
                  },
                },
                [
                  _vm._v("相关offer"),
                  _c("i", [
                    _vm._v(
                      _vm._s(_vm._f("currency")(_vm.data.relateOffers, "", 0))
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
        _c("div", { staticClass: "title-right" }, [
          _c(
            "div",
            {
              staticClass: "date-wrap triangle-drop",
              class: _vm.isFocusDate
                ? "triangle-drop-up"
                : "triangle-drop-down",
            },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "start-placeholder": "开始日期",
                  "range-separator": "-",
                  "end-placeholder": "结束日期",
                  size: "small",
                  editable: false,
                  align: "right",
                  format: "yyyyMMdd",
                  clearable: false,
                  "picker-options": _vm.expireTimeOption,
                  "popper-class": "performance-data-range",
                },
                on: {
                  change: _vm.handleDate,
                  focus: _vm.handleFocusDate,
                  blur: _vm.handleBlurDate,
                },
                model: {
                  value: _vm.dateRange,
                  callback: function ($$v) {
                    _vm.dateRange = $$v
                  },
                  expression: "dateRange",
                },
              }),
            ],
            1
          ),
          _vm.page == "teamData"
            ? _c(
                "div",
                {
                  staticClass: "employee-select-wrap triangle-drop",
                  class: _vm.teamMemberPopoverVisible
                    ? "triangle-drop-up"
                    : "triangle-drop-down",
                },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        "popper-class":
                          "team-member-popover data-team-member-popover",
                        placement: "bottom-end",
                        width: "700",
                        trigger: "click",
                      },
                      model: {
                        value: _vm.teamMemberPopoverVisible,
                        callback: function ($$v) {
                          _vm.teamMemberPopoverVisible = $$v
                        },
                        expression: "teamMemberPopoverVisible",
                      },
                    },
                    [
                      _c("team-member-select", {
                        ref: "teamSelect",
                        attrs: {
                          isAllMembers: true,
                          isShowDimission: false,
                          date: _vm.dateRange,
                          isHistoryData: true,
                          visible: _vm.teamMemberPopoverVisible,
                          selected: _vm.param.userIds,
                        },
                        on: { "item-select-cb": _vm.employeeSelect },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "employee-select-text",
                          attrs: { slot: "reference" },
                          slot: "reference",
                        },
                        [_vm._v(_vm._s(_vm.selectedMembersText))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.page == "teamData" && _vm.subscribedCountFetched
            ? _c(
                "span",
                {
                  staticClass: "data-btn subscribe-btn",
                  on: { click: _vm.handleShowSubscribe },
                },
                [
                  _c("font-icon", { attrs: { href: "#icon-date_ic_book" } }),
                  _vm._v(
                    "\n                报表订阅\n                " +
                      _vm._s(_vm.subscribedCount) +
                      "\n            "
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c(
        "ul",
        { staticClass: "performance-list" },
        [
          _c(
            "li",
            { staticClass: "performance-item" },
            [
              _c("font-icon", {
                staticClass: "item-icon",
                attrs: { href: "#icon-date_ic_candidate" },
              }),
              _c("div", { staticClass: "item-content item-content-multi" }, [
                _c("p", { staticClass: "main-count" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goToDetail($event, {
                            key: "candidateDetails",
                            achievementDetailType: "1",
                          })
                        },
                      },
                    },
                    [
                      _vm._v("人才创建"),
                      _c("i", [
                        _vm._v(
                          _vm._s(_vm._f("currency")(_vm.data.candidates, "", 0))
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c("p", { staticClass: "sub-count" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goToDetail($event, {
                            key: "candidateDetails",
                            achievementDetailType: "2",
                          })
                        },
                      },
                    },
                    [
                      _vm._v("完整简历"),
                      _c("i", [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(
                              _vm.data.completeCandidates,
                              "",
                              0
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c("p", { staticClass: "sub-count" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goToDetail($event, {
                            key: "candidateDetails",
                            achievementDetailType: "3",
                          })
                        },
                      },
                    },
                    [
                      _vm._v("Profile数"),
                      _c("i", [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(
                              _vm.data.profileCandidates,
                              "",
                              0
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "li",
            { staticClass: "performance-item" },
            [
              _c("font-icon", {
                staticClass: "item-icon",
                attrs: { href: "#icon-date_ic_xunfang" },
              }),
              _c("div", { staticClass: "item-content" }, [
                _c("p", { staticClass: "main-count" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goToDetail($event, {
                            key: "candidateInquiryLogDetails",
                            achievementDetailType: "1",
                          })
                        },
                      },
                    },
                    [
                      _vm._v("有效寻访"),
                      _c("i", [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(_vm.data.validInquiryLogs, "", 0)
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c("p", { staticClass: "sub-count" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goToDetail($event, {
                            key: "candidateInquiryLogDetails",
                            achievementDetailType: "2",
                          })
                        },
                      },
                    },
                    [
                      _vm._v("添加寻访"),
                      _c("i", [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(_vm.data.inquiryLogs, "", 0)
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "li",
            { staticClass: "performance-item" },
            [
              _c("font-icon", {
                staticClass: "item-icon",
                attrs: { href: "#icon-date_ic_phone" },
              }),
              _c("div", { staticClass: "item-content" }, [
                _c("p", { staticClass: "main-count" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goToDetail($event, {
                            key: "callRecordDetails",
                            achievementDetailType: "1",
                          })
                        },
                      },
                    },
                    [
                      _vm._v("拨打电话"),
                      _c("i", [
                        _vm._v(
                          _vm._s(_vm._f("currency")(_vm.data.calls, "", 0))
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c("p", { staticClass: "sub-count" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goToDetail($event, {
                            key: "callRecordDetails",
                            achievementDetailType: "2",
                          })
                        },
                      },
                    },
                    [
                      _vm._v("有效通话"),
                      _c("i", [
                        _vm._v(
                          _vm._s(_vm._f("currency")(_vm.data.validCalls, "", 0))
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "li",
            { staticClass: "performance-item" },
            [
              _c("font-icon", {
                staticClass: "item-icon",
                attrs: { href: "#icon-date_ic_zhiwei" },
              }),
              _c("div", { staticClass: "item-content" }, [
                _c("p", { staticClass: "main-count" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goToDetail($event, {
                            key: "jobDetails",
                            achievementDetailType: "1",
                          })
                        },
                      },
                    },
                    [
                      _vm._v("创建职位"),
                      _c("i", [
                        _vm._v(
                          _vm._s(_vm._f("currency")(_vm.data.jobs, "", 0))
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c("p", { staticClass: "sub-count" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goToDetail($event, {
                            key: "jobDetails",
                            achievementDetailType: "2",
                          })
                        },
                      },
                    },
                    [
                      _vm._v("职位相关信息更新"),
                      _c("i", [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(_vm.data.keyUpdateJobs, "", 0)
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "li",
            { staticClass: "performance-item" },
            [
              _c("font-icon", {
                staticClass: "item-icon",
                attrs: { href: "#icon-date_ic_order" },
              }),
              _c("div", { staticClass: "item-content item-content-multi" }, [
                _c("p", { staticClass: "main-count" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goToDetail($event, {
                            key: "orderDetails",
                            achievementDetailType: "1",
                          })
                        },
                      },
                    },
                    [
                      _vm._v("接单次数"),
                      _c("i", [
                        _vm._v(
                          _vm._s(_vm._f("currency")(_vm.data.orders, "", 0))
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c("p", { staticClass: "sub-count" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goToDetail($event, {
                            key: "orderDetails",
                            achievementDetailType: "2",
                          })
                        },
                      },
                    },
                    [
                      _vm._v("内部职位"),
                      _c("i", [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(_vm.data.internalOrders, "", 0)
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c("p", { staticClass: "sub-count" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goToDetail($event, {
                            key: "orderDetails",
                            achievementDetailType: "3",
                          })
                        },
                      },
                    },
                    [
                      _vm._v("外部职位"),
                      _c("i", [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(_vm.data.marketOrders, "", 0)
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "li",
            { staticClass: "performance-item" },
            [
              _c("font-icon", {
                staticClass: "item-icon",
                attrs: { href: "#icon-date_ic_recommend" },
              }),
              _c("div", { staticClass: "item-content item-content-multi" }, [
                _c("p", { staticClass: "main-count" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goToDetail($event, {
                            key: "recommendationDetails",
                            achievementDetailType: "1",
                          })
                        },
                      },
                    },
                    [
                      _vm._v("推荐人才"),
                      _c("i", [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(
                              _vm.data.recommendCandidates,
                              "",
                              0
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c("p", { staticClass: "sub-count" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goToDetail($event, {
                            key: "recommendationDetails",
                            achievementDetailType: "2",
                          })
                        },
                      },
                    },
                    [
                      _vm._v("接受推荐"),
                      _c("i", [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(
                              _vm.data.acceptedRecommendCandidates,
                              "",
                              0
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c("p", { staticClass: "sub-count" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goToDetail($event, {
                            key: "recommendationDetails",
                            achievementDetailType: "3",
                          })
                        },
                      },
                    },
                    [
                      _vm._v("推荐淘汰"),
                      _c("i", [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(
                              _vm.data.rejectedRecommendCandidates,
                              "",
                              0
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "li",
            { staticClass: "performance-item" },
            [
              _c("font-icon", {
                staticClass: "item-icon",
                attrs: { href: "#icon-date_ic_cishu" },
              }),
              _c("div", { staticClass: "item-content item-content-multi" }, [
                _c("p", { staticClass: "main-count" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goToDetail($event, {
                            key: "recommendationDetails",
                            achievementDetailType: "4",
                          })
                        },
                      },
                    },
                    [
                      _vm._v("人才推荐"),
                      _c("i", [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(
                              _vm.data.candidateRecommends,
                              "",
                              0
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c("p", { staticClass: "sub-count" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goToDetail($event, {
                            key: "recommendationDetails",
                            achievementDetailType: "5",
                          })
                        },
                      },
                    },
                    [
                      _vm._v("接受推荐"),
                      _c("i", [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(
                              _vm.data.acceptedCandidateRecommends,
                              "",
                              0
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c("p", { staticClass: "sub-count" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goToDetail($event, {
                            key: "recommendationDetails",
                            achievementDetailType: "6",
                          })
                        },
                      },
                    },
                    [
                      _vm._v("推荐淘汰"),
                      _c("i", [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(
                              _vm.data.rejectedCandidateRecommends,
                              "",
                              0
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "li",
            { staticClass: "performance-item" },
            [
              _c("font-icon", {
                staticClass: "item-icon",
                attrs: { href: "#icon-date_ic_success" },
              }),
              _c("div", { staticClass: "item-content item-content-multi" }, [
                _c("p", { staticClass: "main-count" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goToDetail($event, {
                            key: "recommendationDetails",
                            achievementDetailType: "8",
                          })
                        },
                      },
                    },
                    [
                      _vm._v("面试通过"),
                      _c("i", [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(
                              _vm.data.interviewPassedCandidates,
                              "",
                              0
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c("p", { staticClass: "sub-count" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goToDetail($event, {
                            key: "recommendationDetails",
                            achievementDetailType: "7",
                          })
                        },
                      },
                    },
                    [
                      _vm._v("面试人才"),
                      _c("i", [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(
                              _vm.data.interviewCandidates,
                              "",
                              0
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c("p", { staticClass: "sub-count" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goToDetail($event, {
                            key: "recommendationDetails",
                            achievementDetailType: "9",
                          })
                        },
                      },
                    },
                    [
                      _vm._v("面试失败"),
                      _c("i", [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(
                              _vm.data.interviewFailedCandidates,
                              "",
                              0
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm.page == "personalData"
            ? [
                _c(
                  "li",
                  { staticClass: "performance-item" },
                  [
                    _c("font-icon", {
                      staticClass: "item-icon",
                      attrs: { href: "#icon-date_ic_candidate" },
                    }),
                    _c("div", { staticClass: "item-content" }, [
                      _c("p", { staticClass: "main-count" }, [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.goToDetail($event, {
                                  key: "trackingListDetails",
                                })
                              },
                            },
                          },
                          [
                            _vm._v("TrackingList"),
                            _c("i", [_vm._v(_vm._s(_vm.data.tackingLists))]),
                          ]
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm.isGeneralCoordinator
                  ? _c(
                      "li",
                      { staticClass: "performance-item" },
                      [
                        _c("font-icon", {
                          staticClass: "item-icon",
                          attrs: { href: "#icon-zonghexietiaoren" },
                        }),
                        _c(
                          "div",
                          { staticClass: "item-content item-content-multi" },
                          [
                            _c("p", { staticClass: "sub-count" }, [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToDetail($event, {
                                        key: "generalCoordinatorRecommendationDetails",
                                        achievementDetailType: "12",
                                      })
                                    },
                                  },
                                },
                                [
                                  _vm._v("综合协调人客户推荐数"),
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.data
                                            .generalCoordinatorCandidateRecommends,
                                          "",
                                          0
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("p", { staticClass: "sub-count" }, [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToDetail($event, {
                                        key: "generalCoordinatorRecommendationDetails",
                                        achievementDetailType: "13",
                                      })
                                    },
                                  },
                                },
                                [
                                  _vm._v("综合协调人客户面试数"),
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.data
                                            .generalCoordinatorInterviewCandidates,
                                          "",
                                          0
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("p", { staticClass: "sub-count" }, [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpOfferStatement(
                                        $event,
                                        "clientOffer"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v("综合协调人客户offer数"),
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.data.generalCoordinatorOffers,
                                          "",
                                          0
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "li",
                  { staticClass: "performance-item" },
                  [
                    _c("font-icon", {
                      staticClass: "item-icon",
                      attrs: { href: "#icon-yewuxian" },
                    }),
                    _c(
                      "div",
                      { staticClass: "item-content item-content-multi" },
                      [
                        _c("p", { staticClass: "sub-count" }, [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail($event, {
                                    key: "deliveryTargetReport",
                                    deliveryTargetType: "3",
                                  })
                                },
                              },
                            },
                            [_vm._v("交付目标-客户"), _c("i")]
                          ),
                        ]),
                        _vm.isBusinessCoordinator
                          ? _c("p", { staticClass: "sub-count" }, [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToDetail($event, {
                                        key: "businessLineData",
                                      })
                                    },
                                  },
                                },
                                [_vm._v("业务线数据"), _c("i")]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  { staticClass: "performance-item performance-item-single" },
                  [
                    _c("font-icon", {
                      staticClass: "item-icon",
                      attrs: { href: "#icon-a-bianzu3" },
                    }),
                    _c(
                      "div",
                      { staticClass: "item-content item-content-multi" },
                      [
                        _c("p", { staticClass: "sub-count sub-count" }, [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goToDetail($event, {
                                    key: "evaluateCountReport",
                                  })
                                },
                              },
                            },
                            [
                              _vm._v("评价数 "),
                              _c("i", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.data.recommendationEvaluateCount,
                                      "",
                                      0
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.page == "teamData"
            ? [
                _c(
                  "li",
                  {
                    staticClass: "performance-item",
                    class: _vm.userInfo.isCFUser
                      ? ""
                      : "performance-item-single",
                  },
                  [
                    _c("font-icon", {
                      staticClass: "item-icon",
                      attrs: { href: "#icon-date_ic_candidate" },
                    }),
                    _c("div", { staticClass: "item-content" }, [
                      _c("p", { staticClass: "main-count" }, [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.goToDetail($event, {
                                  key: "trackingListDetails",
                                })
                              },
                            },
                          },
                          [
                            _vm._v("Tracking List"),
                            _c("i", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.data.tackingLists,
                                    "",
                                    0
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _vm.isAdminOrFirstLevelTeamLeader
                        ? _c("p", { staticClass: "sub-count" }, [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.goToDetail($event, {
                                      key: "leaderFeedbackData",
                                    })
                                  },
                                },
                              },
                              [_vm._v("Tracking List周报反馈数据")]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                _vm.userInfo.isCFUser
                  ? _c(
                      "li",
                      {
                        staticClass: "performance-item",
                        class:
                          _vm.userInfo.isCFUser &&
                          _vm.userInfo.isAdminOrTeamLeader
                            ? ""
                            : "performance-item-single",
                      },
                      [
                        _c("font-icon", {
                          staticClass: "item-icon",
                          attrs: { href: "#icon-zhitongche-2" },
                        }),
                        _c(
                          "div",
                          { staticClass: "item-content item-content-multi" },
                          [
                            _c("p", { staticClass: "main-count" }, [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToDetail($event, {
                                        key: "jdtReportData",
                                        jdtReportDataType: "0",
                                      })
                                    },
                                  },
                                },
                                [
                                  _vm._v("职通车推荐注册人数"),
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.data.newJDTRegistrant,
                                          "",
                                          0
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("p", { staticClass: "sub-count" }, [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToDetail($event, {
                                        key: "jdtReportData",
                                        jdtReportDataType: "1",
                                      })
                                    },
                                  },
                                },
                                [
                                  _vm._v("分享职位数"),
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.data.jobShareInJDT,
                                          "",
                                          0
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("p", { staticClass: "sub-count" }, [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToDetail($event, {
                                        key: "jdtReportData",
                                        jdtReportDataType: "2",
                                      })
                                    },
                                  },
                                },
                                [
                                  _vm._v("发消息给我的人数"),
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.data.receiveIMMessageByJDTUsers,
                                          "",
                                          0
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("p", { staticClass: "sub-count" }, [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToDetail($event, {
                                        key: "jdtReportData",
                                        jdtReportDataType: "3",
                                      })
                                    },
                                  },
                                },
                                [
                                  _vm._v("我发消息的人数"),
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.data.sendIMMessageToJDTUsers,
                                          "",
                                          0
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.userInfo.isAdministrator || _vm.userInfo.isAdminOrTeamLeader
                  ? _c(
                      "li",
                      { staticClass: "performance-item" },
                      [
                        _c("font-icon", {
                          staticClass: "item-icon",
                          attrs: { href: "#icon-jiaofumubiao" },
                        }),
                        _c("div", { staticClass: "item-content" }, [
                          _c(
                            "p",
                            { staticClass: "sub-count sub-count-padding" },
                            [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToDetail($event, {
                                        key: "deliveryTargetReport",
                                        deliveryTargetType: "0",
                                      })
                                    },
                                  },
                                },
                                [_vm._v("交付目标-客户")]
                              ),
                            ]
                          ),
                          _c("p", { staticClass: "sub-count" }, [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.goToDetail($event, {
                                      key: "deliveryTargetReport",
                                      deliveryTargetType: "1",
                                    })
                                  },
                                },
                              },
                              [_vm._v("交付目标-成员")]
                            ),
                          ]),
                        ]),
                      ],
                      1
                    )
                  : _c("li", { staticClass: "performance-item" }),
                (_vm.userInfo.isAdministrator ||
                  _vm.userInfo.isAdminOrTeamLeader) &&
                _vm.userInfo.isCFUser
                  ? _c(
                      "li",
                      {
                        staticClass: "performance-item performance-item-single",
                      },
                      [
                        _c("font-icon", {
                          staticClass: "item-icon",
                          attrs: { href: "#icon-jifen" },
                        }),
                        _c(
                          "div",
                          { staticClass: "item-content item-content-multi" },
                          [
                            _c("p", { staticClass: "sub-count" }, [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToDetail($event, {
                                        key: "recommendationDetails",
                                        achievementDetailType: "9",
                                      })
                                    },
                                  },
                                },
                                [
                                  _vm._v("团队积分"),
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.data.interviewFailedCandidates,
                                          "",
                                          0
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.userInfo.isCFUser
                  ? _c("li", { staticClass: "performance-item" })
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }