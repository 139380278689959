<template>
    <!-- 个人数据Data -->
    <div class="data-container">
        <div class="data-left-wrap">
            <div class="data-pannel green-line">
                <daily-summary></daily-summary>
            </div>
        </div>
        <div class="data-right-wrap">
            <div class="data-pannel blue-line performance-data">
                <performance-data page="personalData"></performance-data>
            </div>
            <div class="data-pannel orange-line">
                <talent-reuse></talent-reuse>
            </div>
        </div>
    </div>
</template>
<script>
import PerformanceData from './performanceData';
import DailySummary from "./dailySummary.vue";
import TalentReuse from './talentReuse'
export default {
    name: 'Data',
    components:{
        DailySummary,
        PerformanceData,
        TalentReuse
    },
    data() {
        return {

        }
    },
    mounted() {
        
    },
    methods: {
        
    }
}
</script>
<style lang="scss">
.pannel-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-text{
        font-size: 20px;
        color: #444;
        font-weight: bold;
    }
    .title-right{
        display: flex;
    }
}
</style>
<style lang="scss" scoped>
.data-container{
    display: flex;
    justify-content: center;
    padding: 10px 20px 20px;
    height: 100%;
    background: url('~@src/assets/images/home/home_bg.png') bottom center no-repeat;
    overflow-x: hidden;
}

.data-pannel{
    //height: 50%;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #eee;
    box-sizing: border-box;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.15);
    &.green-line{
        padding: 20px;
        border-top: 4px solid $primary;
        height: 100%;
        background: #fff url("~@src/assets/images/workbench/date_bgpic_gzxj.png") no-repeat bottom;
        background-size: 100%;
    }
    &.blue-line{
        border-top: 4px solid #4A90E2;
        // height: 56%;
        height: 100%;
        flex-grow: 1.5;
        margin-bottom: 20px;
    }
    &.orange-line{
        border-top: 4px solid #F5A623;
        flex-grow: 1;
        height: 40%;
    }
}
.data-left-wrap{
    flex-grow: 1;
    min-width: 353px;
    max-width: 400px;
    // height: 100%;
    // max-height: 694px;
    height: 805px;
    box-sizing: border-box;
    margin-right: 20px;
}
.data-right-wrap{
    flex-grow: 2;
    min-width: 681px;
    max-width: 800px;
    // height: 100%;
    // max-height: 694px;
    height: 805px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

</style>
