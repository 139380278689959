<template>
    <div class="daily-summary" v-loading="loading">
        <div class="daily-summary-header">
            <div class="daily-summary-top">
                <span class="daily-summary-title">
                    今日工作小结
                </span>
                <span class="daily-summary-date">
                    {{dailySummary.date}}
                </span>
                <el-date-picker
                    class="daily-summary-past"
                    v-model="dailySummaryDate"
                    type="date"
                    placeholder="往期日报"
                    :clearable="false"
                    :editable="false"
                    align="right"
                    :popper-class="!dailySummaryDate ? 'daily-summary-past-popover today-choose' : ''"
                    :picker-options="datePickerOption"
                    :class="dailySummaryDate ? 'icon-hide' : ''"
                    @change="getDailySummary(dailySummaryDate)">
                </el-date-picker>
            </div>
            <div class="daily-summary-offer">
                offer
                <span
                    class="font-green"
                    @click="goToDetail({key: 'recommendationDetails', achievementDetailType: '10'})">
                    {{dailySummary.offers}}
                </span>
            </div>
            <el-popover
                class="daily-summary-qcode"
                popper-class="daily-summary-qcode-popover"
                trigger="hover"
                width="80"
                placement="top"
                :visible-arrow="false">
                <div v-if="qrcode">
                    <img :src="qrcode">
                </div>
                <el-button
                    slot="reference"
                    class="daily-summary-qcode-button"
                    :class="isShowQcodeCard ? 'qcode-show' : ''"
                    @click="isShowQcodeCard = !isShowQcodeCard">
                    <font-icon class="icon-default" href="#icon-date_ic_scan"></font-icon>
                    <font-icon class="icon-hover" href="#icon-date_ic_scan_hover"></font-icon>
                    <span>&nbsp;扫码分享</span>
                </el-button>
            </el-popover>
            <ul class="daily-summary-menu">
                <li
                    class="daily-summary-menu-item"
                    :class="dailySummaryType == 'order' ? 'isActive' : ''"
                    @click="dailySummaryType = 'order'">
                    订单
                </li>
                <li
                    class="daily-summary-menu-item"
                    :class="dailySummaryType == 'candidate' ? 'isActive' : ''"
                    @click="dailySummaryType = 'candidate'">
                    人才
                </li>
                <li
                    class="daily-summary-menu-item"
                    :class="dailySummaryType == 'recommend' ? 'isActive' : ''"
                    @click="dailySummaryType = 'recommend'">
                    业务流程
                </li>
            </ul>
        </div>
        <div class="daily-summary-list">
            <ul>
                <li
                    v-for="(item, index) in dailySummaryList[dailySummaryType]"
                    :key="index">
                    <template v-if="item.hasChildren">
                        <p class="daily-summary-item" :class="dailySummary[item.type] !== undefined ? 'daily-summary-item-hover' : ''">
                            {{item.text}}
                            <span class="data-num" @click="goToDetail(item.param)" v-if="dailySummary[item.type] !== undefined">{{dailySummary[item.type]}}</span>
                        </p>
                        <ul :style="index == 3 ? 'margin-bottom: 0;' : ''">
                            <template v-if="item.type !== 'candidateCommunication'">
                                <li v-for="(subItem, subIndex) in item.list" :key="subIndex">
                                    <p class="daily-summary-sub-item">
                                        {{subItem.text}}
                                        <span class="data-num" @click="goToDetail(subItem.param)">{{dailySummary[subItem.type]}}</span>
                                    </p>
                                </li>
                            </template>
                            <template v-else>
                                <li>
                                    <p class="daily-summary-sub-item">
                                        添加寻访/有效寻访
                                        <span class="data-num" @click="goToDetail({key: 'candidateInquiryLogDetails', achievementDetailType: '1'})">{{dailySummary.validInquiryLogs}}</span>
                                        <span style="float: right;"> / </span>
                                        <span class="data-num" @click="goToDetail({key: 'candidateInquiryLogDetails', achievementDetailType: '2'})">{{dailySummary.inquiryLogs}}</span>
                                    </p>
                                </li>
                                <li>
                                    <p class="daily-summary-sub-item">
                                        拨打电话/有效拨打电话数
                                        <span class="data-num" @click="goToDetail({key: 'callRecordDetails', achievementDetailType: '2'})">{{dailySummary.validCalls}}</span>
                                        <span style="float: right;"> / </span>
                                        <span class="data-num" @click="goToDetail({key: 'callRecordDetails', achievementDetailType: '1'})">{{dailySummary.calls}}</span>
                                    </p>
                                </li>
                            </template>
                        </ul>
                    </template>
                    <template v-else>
                        <p class="daily-summary-item daily-summary-item-hover">
                            {{item.text}}
                            <span class="data-num" @click="goToDetail(item.param)">{{dailySummary[item.type]}}</span>
                        </p>
                    </template>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import dailySummaryTypeJson from "#/js/config/dailySummaryList.json";
import moment from 'moment';
import { daily_report as daily_report_url } from '#/js/config/api.json';

export default {
    name: 'dailySummary',
    components:{

    },
    data() {
        return {
            dailySummary: {
                acceptedCandidateRecommends: 0,
                acceptedRecommendCandidates: 0,
                calls: 0,
                candidateContactViews: 0,
                candidateDetailViews: 0,
                candidateKeyUpdates: 0,
                candidateRecommends: 0,
                candidateUpdates: 0,
                candidates: 0,
                completeCandidates: 0,
                date: moment().format('YYYY-MM-DD'),
                inquiryLogs: 0,
                interviewCandidates: 0,
                interviewFailedCandidates: 0,
                interviewPassedCandidates: 0,
                jobs: 0,
                keyUpdateJobs: 0,
                offers: 0,
                onboards: 0,
                orders: 0,
                profileCandidates: 0,
                recommendCandidates: 0,
                rejectedCandidateRecommends: 0,
                rejectedRecommendCandidates: 0,
                validCalls: 0,
                validInquiryLogs: 0
            },
            dailySummaryType: 'candidate',
            dailySummaryDate: '',
            qrcode: '',
            datePickerOption: {
                disabledDate(time) {
                    return time.getTime() > new Date(moment().format('YYYY-MM-DD').replace(/\-/ig, '\/')).getTime();
                }
            },
            isShowQcodeCard: false,
            dailySummaryList: dailySummaryTypeJson,
            loading: false,
            today: "2019-03-20"
        }
    },
    computed: {
        userId() {
            return this.$store.state.user.userInfo.id;
        }
    },
    mounted() {
        this.today = moment().format('YYYY-MM-DD');
        this.getDailySummary(this.today);
    },
    methods: {
        getDailySummary(date) {
            let timeStamp;
            if(date == this.today) {
                timeStamp = new Date().getTime();
            } else {
                timeStamp = new Date(date).getTime();
            }
            this.qrcode = `${_host.portal}/Sharing/QRCode?url=`+encodeURIComponent(`${_host.h5_page}/report.html?date=${timeStamp}&userid=${this.userId}&isShare=true`);
            if(this.dailySummaryDate) {
                let pastPopover = document.querySelector('.daily-summary-past-popover');
                if(pastPopover.className.indexOf('today-choose') > -1) {
                    pastPopover.classList.remove('today-choose');
                }
            }
            this.loading = true;
            _request({
                url: daily_report_url.my_daily_report,
                header: {
                    "UPASession": JSON.stringify({
                        "PageViewId" : this.uuid
                    })
                },
                data: {
                    date: date,
                    userId: this.userId,
                    triggerType: 2
                },
                method: 'GET'
            }).then(res => {
                this.loading = false;
                if(res) {
                    this.dailySummary = res;
                }
            }).catch(err => {
                this.loading = false;
                console.log(err);
            });
        },
        goToDetail(param) {
            param.userIds = [this.userId];
            param.startDate = this.dailySummary.date;
            param.endDate = this.dailySummary.date;
            // param.triggerType = 2;
            sessionStorage.removeItem('perpormancesDetailData');
            localStorage.setItem('perpormancesDetailData', JSON.stringify(param));
            window.open('/Headhunting/MyCompany.html?frompage=daily#/performancesDetail', '_blank');
            // window.open('/?frompage=daily#/performancesDetail', '_blank');
            return false;
        }
    }
}
</script>
<style lang="scss" scoped>
.daily-summary {
    font-size: 15px;
    color: #444;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: transparent;
    .daily-summary-header {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .daily-summary-top {
        width: 100%;
        padding-left: 10px;
        position: relative;
        .daily-summary-title {
            font-weight: bold;
            font-size: 20px;
            line-height: 28px;
        }
        .daily-summary-date {
            color: #666;
        }
        .daily-summary-past {
            width: 86px;
            height: 24px;
            font-size: 12px;
            line-height: 24px;
            border-radius: 24px;
            padding-left: 16px;
            color: #666;
            border: 1px solid #DDDDDD;
            display: inline-block;
            float: right;
            text-align: center;
            &:hover, &:active, &:focus {
                background: $primary;
                border-color: $primary;
            }
            &.icon-hide {
                width: 96px;
            }
        }
    }
    .daily-summary-offer {
        margin-top: 20px;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .font-green {
            margin-top: 7px;
            color: $primary;
            font-size: 20px;
            font-weight: bold;
            cursor: pointer;
        }
    }
    .daily-summary-qcode-button {
        margin-top: 15px;
        padding: 0;
        color: #666;
        font-size: 14px;
        line-height: 18px;
        background-color: transparent;
        border: none;
        .icon-hover {
            display: none;
        }
        &:hover, &:focus, &:active {
            color: $primary;
            .icon-default {
                display: none;
            }
            .icon-hover {
                display: inline-block;
            }
        }
    }
    .daily-summary-menu {
        width: 228px;
        margin-top: 22px;
        line-height: 28px;
        border-radius: 14px;
        border: 1px solid #DDDDDD;
        display: flex;
        .daily-summary-menu-item {
            width: 76px;
            display: inline-block;
            color: #666;
            font-size: 14px;
            text-align: center;
            cursor: pointer;
            &.isActive {
                color: #fff;
                background: $primary;
                border-radius: 14px;
                position: relative;
                height: 28px;
                top: 0;
                border: 1px solid $primary;
            }
        }
    }
    .daily-summary-list {
        margin-top: 22px;
        .daily-summary-item {
            width: 100%;
            font-size: 16px;
            line-height: 32px;
            text-shadow: 0 0 0.7px #444;
            & + ul {
                font-size: 14px;
                margin-bottom: 10px;
                .daily-summary-sub-item {
                    color: #666;
                    line-height: 32px;
                }
            }
        }
        .daily-summary-item-hover:hover, .daily-summary-sub-item:hover {
            background: #F8F8F8;
            color: $primary !important;
            border-radius: 4px;
        }
        p {
            padding: 0 10px;
            margin-bottom: 0;
        }
        .data-num {
            float: right;
            font-weight: bold;
            cursor: pointer;
        }
    }
}
</style>
<style lang="scss">
.daily-summary-past {
    input.el-input__inner {
        background-color: transparent;
        padding: 0;
        border: none !important;
        height: 100%;
        text-align: center;
        cursor: pointer;
        &::placeholder {
            color: #666;
        }
    }
    .el-input__prefix {
        left: 9px;
        top: 1px;
    }
    .el-icon-date{
        width: 12px;
        min-width: 12px;
        display: flex;
        background: url("~@src/assets/images/date_ic_history.png") no-repeat center;
        background-size: contain;
        transition: none;
        &::before {
            content: "";
        }
    }
    &:hover {
        input.el-input__inner {
            color: #fff;
            &::placeholder {
                color: #fff;
            }
        }
        .el-icon-date{
            background: url("~@src/assets/images/date_ic_history_hover.png") no-repeat center;
            background-size: contain;
        }
    }
}
.daily-summary-qcode-popover.el-popover {
    border: 1px solid #38BC9D;
    min-width: 80px;
    height: 80px;
    margin-bottom: 5px;
    padding: 0;
    img {
        width: 100%;
        height: 100%;
    }
}
.daily-summary-past-popover.el-date-picker {
    width: 292px;
    font-size: 12px;
    margin-top: 8px;
    padding: 5px;
    .el-date-picker__header {
        margin-bottom: 0;
        .el-date-picker__header-label {
            font-weight: bold;
            font-size: 12px;
            color: #444;
        }
    }
    .el-picker-panel__content {
        margin: 0;
        width: 100%;
        padding: 0 11px;
        .el-date-table td {
            &.available {
                color: #666;
                &:hover{
                    color: #38BC9D;
                }
            }
            &.disabled div{
                background-color: inherit;
                color: #666;
            }
            &.current:not(.disabled) span {
                font-weight: bold
            }
        }
    }
    .popper__arrow {
        display: none;
    }
    &.today-choose {
        .el-date-table td.today span {
            color: #FFFFFF;
            background-color: #38BC9D;
        }
    }
}
</style>
