var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "data-container" }, [
    _c("div", { staticClass: "data-left-wrap" }, [
      _c(
        "div",
        { staticClass: "data-pannel green-line" },
        [_c("daily-summary")],
        1
      ),
    ]),
    _c("div", { staticClass: "data-right-wrap" }, [
      _c(
        "div",
        { staticClass: "data-pannel blue-line performance-data" },
        [_c("performance-data", { attrs: { page: "personalData" } })],
        1
      ),
      _c(
        "div",
        { staticClass: "data-pannel orange-line" },
        [_c("talent-reuse")],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }