var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "talent-reuse",
    },
    [
      _c("div", { staticClass: "tab-title" }, [
        _c(
          "span",
          {
            staticClass: "title-text",
            class: { active: _vm.activeName == "recommend-progress" },
            on: {
              click: function ($event) {
                return _vm.handleClick("recommend-progress")
              },
            },
          },
          [_vm._v("推荐进展")]
        ),
        _c(
          "span",
          {
            staticClass: "title-text",
            class: { active: _vm.activeName == "talent-reuse" },
            on: {
              click: function ($event) {
                return _vm.handleClick("talent-reuse")
              },
            },
          },
          [_vm._v("人才复用")]
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName == "recommend-progress",
              expression: "activeName == 'recommend-progress'",
            },
          ],
          staticClass: "recommend-progress-data-content",
        },
        [
          _c(
            "div",
            { staticClass: "option" },
            [
              _c(
                "div",
                { staticClass: "date-wrap" },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "start-placeholder": "开始日期",
                      "range-separator": "-",
                      "end-placeholder": "结束日期",
                      size: "small",
                      editable: false,
                      align: "right",
                      format: "yyyyMMdd",
                      clearable: false,
                      "picker-options": _vm.expireTimeOption,
                    },
                    on: { change: _vm.handleDate },
                    model: {
                      value: _vm.dateRange,
                      callback: function ($$v) {
                        _vm.dateRange = $$v
                      },
                      expression: "dateRange",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "performances-detail-el-export",
                  attrs: {
                    type: "primary",
                    disabled: _vm.isLoadingExport,
                    loading: _vm.isLoadingExport,
                  },
                  on: { click: _vm.handleExport },
                },
                [_vm._v("导出明细")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "data-wrap" }, [
            _c("div", { staticClass: "total" }, [
              _vm._v("产生推荐："),
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v(_vm._s(_vm.recommendProcessDetail.total)),
              ]),
            ]),
            _c("div", { staticClass: "data-detail" }, [
              _c("ul", { staticClass: "left" }, [
                _c("li", [
                  _c("span", [_vm._v("待反馈：")]),
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      _vm._s(_vm.recommendProcessDetail.hrfeedBack?.count || 0)
                    ),
                  ]),
                ]),
                _c("li", [
                  _c("span", [_vm._v("淘汰（未进入面试）：")]),
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      _vm._s(
                        _vm.recommendProcessDetail.obsoletedFrontInterview
                          ?.count || 0
                      )
                    ),
                  ]),
                ]),
                _c("li", [
                  _c("span", [_vm._v("面试中：")]),
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      _vm._s(_vm.recommendProcessDetail.interview?.count || 0)
                    ),
                  ]),
                ]),
                _c("li", [
                  _c("span", [_vm._v("淘汰（面挂）：")]),
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      _vm._s(
                        _vm.recommendProcessDetail.obsoletedBackInterview
                          ?.count || 0
                      )
                    ),
                  ]),
                ]),
                _c("li", [
                  _c("span", [_vm._v("offering：")]),
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      _vm._s(_vm.recommendProcessDetail.offering?.count || 0)
                    ),
                  ]),
                ]),
              ]),
              _c("ul", { staticClass: "right" }, [
                _c("li", [
                  _c("span", [_vm._v("淘汰（未进入offer）：")]),
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      _vm._s(
                        _vm.recommendProcessDetail.obsoletedOffering?.count || 0
                      )
                    ),
                  ]),
                ]),
                _c("li", [
                  _c("span", [_vm._v("已offer：")]),
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      _vm._s(_vm.recommendProcessDetail.offer?.count || 0)
                    ),
                  ]),
                ]),
                _c("li", [
                  _c("span", [_vm._v("淘汰（飞单）：")]),
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      _vm._s(
                        _vm.recommendProcessDetail.obsoletedOffer?.count || 0
                      )
                    ),
                  ]),
                ]),
                _c("li", [
                  _c("span", [_vm._v("入职")]),
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      _vm._s(_vm.recommendProcessDetail.onboard?.count || 0)
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName == "talent-reuse",
              expression: "activeName == 'talent-reuse'",
            },
          ],
          staticClass: "data-content",
        },
        [
          _c("div", { staticClass: "data-list" }, [
            _c("h3", { staticClass: "talent-reuse-h3" }, [_vm._v("我的人才")]),
            _c("ul", [
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goToTalentReuse({
                        key: "ViewCandidates",
                        candidateViewType: 0,
                        isMyCandidate: true,
                      })
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    我的简历被浏览\n                    "
                  ),
                  _c("span", { staticClass: "talent-reuse-number" }, [
                    _vm._v(
                      _vm._s(_vm.workbenchPanelData.myResumeByViewCount || 0)
                    ),
                  ]),
                ]
              ),
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goToTalentReuse({
                        key: "ViewCandidates",
                        candidateViewType: 1,
                        isMyCandidate: true,
                      })
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    我的简历电话被查看\n                    "
                  ),
                  _c("span", { staticClass: "talent-reuse-number" }, [
                    _vm._v(
                      _vm._s(
                        _vm.workbenchPanelData.myResumePhoneByViewCount || 0
                      )
                    ),
                  ]),
                ]
              ),
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goToTalentReuse({
                        key: "ViewCandidates",
                        candidateViewType: 2,
                        isMyCandidate: true,
                      })
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    我的简历邮箱被查看\n                    "
                  ),
                  _c("span", { staticClass: "talent-reuse-number" }, [
                    _vm._v(
                      _vm._s(
                        _vm.workbenchPanelData.myResumeEmailByViewCount || 0
                      )
                    ),
                  ]),
                ]
              ),
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goToTalentReuse({
                        key: "RecommendCandidates",
                        recommendType: 0,
                        isMyCandidate: true,
                      })
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    我的简历被推荐\n                    "
                  ),
                  _c("span", { staticClass: "talent-reuse-number" }, [
                    _vm._v(
                      _vm._s(
                        _vm.workbenchPanelData.myResumeByRecommendCount || 0
                      )
                    ),
                  ]),
                ]
              ),
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goToTalentReuse({
                        key: "RecommendCandidates",
                        recommendType: 1,
                        isMyCandidate: true,
                      })
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    我的简历被推荐拿到offer\n                    "
                  ),
                  _c("span", { staticClass: "talent-reuse-number" }, [
                    _vm._v(
                      _vm._s(
                        _vm.workbenchPanelData.myResumeByRecommendOfferCount ||
                          0
                      )
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "data-list" }, [
            _c("h3", { staticClass: "talent-reuse-h3 blue-title-pre" }, [
              _vm._v("他人人才"),
            ]),
            _c("ul", [
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goToTalentReuse({
                        key: "ViewCandidates",
                        candidateViewType: 0,
                        isMyCandidate: false,
                      })
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    我浏览同事的简历\n                    "
                  ),
                  _c("span", { staticClass: "talent-reuse-number" }, [
                    _vm._v(
                      _vm._s(_vm.workbenchPanelData.viewOtherResumeCount || 0)
                    ),
                  ]),
                ]
              ),
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goToTalentReuse({
                        key: "ViewCandidates",
                        candidateViewType: 1,
                        isMyCandidate: false,
                      })
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    我查看同事的简历电话\n                    "
                  ),
                  _c("span", { staticClass: "talent-reuse-number" }, [
                    _vm._v(
                      _vm._s(
                        _vm.workbenchPanelData.viewOtherResumePhoneCount || 0
                      )
                    ),
                  ]),
                ]
              ),
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goToTalentReuse({
                        key: "ViewCandidates",
                        candidateViewType: 2,
                        isMyCandidate: false,
                      })
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    我查看同事的简历邮箱\n                    "
                  ),
                  _c("span", { staticClass: "talent-reuse-number" }, [
                    _vm._v(
                      _vm._s(
                        _vm.workbenchPanelData.viewOtherResumeEmailCount || 0
                      )
                    ),
                  ]),
                ]
              ),
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goToTalentReuse({
                        key: "RecommendCandidates",
                        recommendType: 0,
                        isMyCandidate: false,
                      })
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    我推荐同事的简历\n                    "
                  ),
                  _c("span", { staticClass: "talent-reuse-number" }, [
                    _vm._v(
                      _vm._s(
                        _vm.workbenchPanelData.recommendOtherResumeCount || 0
                      )
                    ),
                  ]),
                ]
              ),
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goToTalentReuse({
                        key: "RecommendCandidates",
                        recommendType: 1,
                        isMyCandidate: false,
                      })
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    我推荐同事的简历拿到offer\n                    "
                  ),
                  _c("span", { staticClass: "talent-reuse-number" }, [
                    _vm._v(
                      _vm._s(
                        _vm.workbenchPanelData.recommendOtherResumeOfferCount ||
                          0
                      )
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }